import React, { FC, useCallback, useMemo } from 'react';

import { Document } from '@contentful/rich-text-types';
import { BrandedSearchRelatedCard } from '@vakantiesnl/components/src/molecules/BrandedSearchRelatedCard';
import { ScrollableCardGroup } from '@vakantiesnl/components/src/molecules/ScrollableCardGroup';
import { BandedSearchPageCardColor, BandedSearchPageCardVariant, LinkCard } from '@vakantiesnl/types';
import { GTMPageType } from '@vakantiesnl/types/src/gtm';

import { useStyles } from './BrandedSearchRelatedCards.style';

interface Props {
	cards: LinkCard[];
	title?: string;
	document?: Document | null;
	departureDate?: string[] | null;
	dataCy?: string;
	cardVariant?: BandedSearchPageCardVariant | null;
	backgroundColor?: BandedSearchPageCardColor | null;
	withScrollButtons?: boolean;
	pageType: GTMPageType;
	listClassName?: string;
	containerClassName?: string;
}

export const BrandedSearchRelatedCards: FC<Props> = ({
	title,
	document,
	cards,
	departureDate,
	dataCy = 'branded-search-related-cards',
	cardVariant = 'textOnly',
	backgroundColor,
	withScrollButtons = false,
	pageType,
	listClassName,
	containerClassName,
}) => {
	const { classes, cx } = useStyles();

	/* Track the clicking of the cards */
	const handleCardClick = useCallback(
		({ label, link }: { label: string; link: string }) =>
			import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
				import('@vakantiesnl/services/src/gtm/search').then(({ brandedSearchCardClick }) =>
					track(brandedSearchCardClick({ label, link, pageType })),
				),
			),
		[pageType],
	);

	const CardContent = useMemo(
		() =>
			cards
				.filter(
					(card) =>
						/** Hide card for the image variant when they don't have an image */
						(cardVariant === 'image' && (card.cardImage || card.imgSrc)) ||
						/**
						 * Hide card for the vertical image variant when they don't have an vertical image */
						(cardVariant === 'verticalImage' && card.verticalImgSrc) ||
						/** Hide card for the icon variant when they don't have an icon */
						(cardVariant === 'textIcon' && card.iconType),
				)
				.map((card, index) => (
					<li
						key={`${card?.title}-${index}`}
						className={cx(classes.cardItem, { [classes.verticalCard]: cardVariant === 'verticalImage' })}
						data-cy="branded-card-item"
					>
						<BrandedSearchRelatedCard
							departureDate={departureDate}
							card={card}
							variant={cardVariant}
							color={backgroundColor}
							index={index}
							onClick={handleCardClick}
						/>
					</li>
				)),
		[
			backgroundColor,
			cardVariant,
			cards,
			classes.cardItem,
			classes.verticalCard,
			cx,
			departureDate,
			handleCardClick,
		],
	);

	return (
		<ScrollableCardGroup
			heading={title}
			document={document}
			listDataCy={dataCy}
			withScrollButtons={withScrollButtons}
			listClassName={cx({ [classes.verticalCardList]: cardVariant === 'verticalImage' }, listClassName)}
			containerClassName={containerClassName}
		>
			{CardContent}
		</ScrollableCardGroup>
	);
};
