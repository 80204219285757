import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	priceShapeContainer: {
		position: 'relative',
	},
	ratingBoxIcon: {
		width: '85px',
		height: '85px',
		fill: theme.palette.neutral[0],
	},
	price: {
		width: 'auto',
	},
	priceShapeContent: {
		position: 'absolute',
		display: 'flex',
		gap: theme.spacing.micro,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
}));
