import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = makeStyles()((theme) => ({
	container: {
		marginTop: theme.spacing.xlarge,
		marginBottom: theme.spacing.xlarge,
	},
	topWrapper: {
		display: 'flex',
		gap: theme.spacing.small,
		marginBottom: theme.spacing.small,
	},
	headerContainer: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.xxsmall,
	},
	scrollButtonsContainer: {
		flex: '0 0 auto',
		display: 'flex',
		marginLeft: 'auto',
		justifyContent: 'flex-end',
		alignItems: 'end',
		gap: theme.spacing.small,
		marginBottom: theme.spacing.medium,
	},
	heading: {
		marginBottom: 0,
		color: theme.heading.color,
	},
	cardsList: {
		display: 'flex',
		width: '100%',
		overflowY: 'hidden',
		listStyle: 'none',
		gap: theme.spacing.small,
		// scrolling without scrollbar and with snap
		scrollSnapType: 'x mandatory',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',

		['&::-webkit-scrollbar']: {
			display: 'none',
		},

		['& > *']: {
			scrollSnapAlign: 'start',
		},
	},
}));

export default useStyles;
