import { FC, PropsWithChildren, ReactNode } from 'react';

import { documentToReactComponents, type Options } from '@contentful/rich-text-react-renderer';
import { Document, INLINES, Text, BLOCKS, Node, NodeData } from '@contentful/rich-text-types';
import { useTheme } from '@mui/material/styles';
import { LinkCard } from '@vakantiesnl/components/src/__LEGACY__/atoms/LinkCard';
import { DownloadImage } from '@vakantiesnl/components/src/__LEGACY__/molecules/DownloadImage';
import { ImageGrid } from '@vakantiesnl/components/src/__LEGACY__/molecules/ImageGrid';
import { Alert } from '@vakantiesnl/components/src/atoms/Alert';
import { Button } from '@vakantiesnl/components/src/atoms/Button';
import { HeadingWithTick } from '@vakantiesnl/components/src/atoms/HeadingWithTick';
import { Link, LinkProps } from '@vakantiesnl/components/src/atoms/Link';
import { Typography, TypographyProps } from '@vakantiesnl/components/src/atoms/Typography';
import { UnorderedList } from '@vakantiesnl/components/src/atoms/UnorderedList';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import {
	mapAlertElement,
	mapHeaderWithTickList,
	mapUnorderedList,
	mapButtonRow,
	mapImageGrid,
	mapImageCard,
	mapButtonElement,
	getTarget,
	mapBrandedSearchPageCollection,
} from '@vakantiesnl/services/src/contentful/mapper';
import {
	RawAlertElement,
	RawUnorderedList,
	RawButtonRow,
	RawImageGrid,
	RawImageCard,
	RawButtonElement,
	RawBrandedSearchPageCollection,
	ButtonElement,
} from '@vakantiesnl/types';
import { Entry } from 'contentful';

import useStyles from './RichtText.styles';
import { returnValidUrl } from '../../utils/linkUtils';
import { useBreakpoints } from '../../utils/useBreakpoints';
import { BrandedSearchRelatedCards } from '../BrandedSearchRelatedCards';

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

type RichTextProps = {
	document: Document;
};

interface ImageWrapperProps {
	fileData: NodeData;
}

const ImageWrapper: FC<ImageWrapperProps> = ({ fileData }) => {
	const { classes } = useStyles();
	const theme = useTheme();

	return (
		<div className={classes.imageContainer}>
			<ImageComponent
				useLoader="Contentful"
				contentfulParams={contentfulParams}
				className={classes.image}
				src={fileData.url}
				alt={fileData.alt ? fileData.alt : ''}
				title={fileData.title}
				width={fileData.width ?? fileData.details.image.width}
				height={fileData.height ?? fileData.details.image.height}
				/** Has to support embedded full-width sized images on content pages, which is 1168 px.
				 * For smaller screens than that, be responsive to the viewport. */
				sizes={`(max-width:${theme.breakpoints.values.lg}px) 100vw, 1168px`}
			/>
		</div>
	);
};

const ParagraphWrapper: FC<PropsWithChildren<TypographyProps>> = ({ children, ...props }) => {
	const { classes } = useStyles();

	return (
		<Typography {...props} className={classes.paragraph}>
			{children}
		</Typography>
	);
};
const ButtonWrapper: FC<PropsWithChildren<ButtonElement>> = ({ ...props }) => {
	const { classes } = useStyles();
	const { isTabletOrIsDesktop } = useBreakpoints();

	return (
		<Link
			href={props.link}
			target={
				props.link.startsWith('http') || props.link.startsWith('www') || props.iconType === 'external'
					? '_blank'
					: undefined
			}
			className={classes.buttonLink}
		>
			<Button
				variant={props.variant}
				size={isTabletOrIsDesktop ? 'medium' : 'small'}
				trailingIcon={props.iconType}
				asText
			>
				{props.title}
			</Button>
		</Link>
	);
};

const TypographyWrapper: FC<PropsWithChildren<TypographyProps>> = ({ children, ...props }) => {
	const { classes } = useStyles();

	return (
		<Typography {...props} className={classes.heading}>
			{children}
		</Typography>
	);
};

const TableWrapper: FC<PropsWithChildren> = ({ children }) => {
	const { classes } = useStyles();
	return (
		<table className={classes.contentfulTable}>
			<tbody>{children}</tbody>
		</table>
	);
};

const LinkWrapper: FC<PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
	const { isTabletOrIsDesktop } = useBreakpoints();
	const { classes } = useStyles();

	return (
		<Link {...props} className={classes.link} size={isTabletOrIsDesktop ? 'medium' : 'small'}>
			{children}
		</Link>
	);
};

const RowWrapper: FC<PropsWithChildren> = ({ children, ...props }) => {
	const { classes } = useStyles();

	return (
		<div {...props} className={classes.row}>
			{children}
		</div>
	);
};

export interface RichTextElementStyling {
	[BLOCKS.EMBEDDED_ENTRY]?: string;
	[BLOCKS.HEADING_1]?: string;
	[BLOCKS.HEADING_2]?: string;
	[BLOCKS.HEADING_3]?: string;
	[BLOCKS.HEADING_4]?: string;
	[BLOCKS.HEADING_5]?: string;
	[BLOCKS.HEADING_6]?: string;
	[BLOCKS.PARAGRAPH]?: string;
	[BLOCKS.UL_LIST]?: string;
}

const options = (isTabletOrIsDesktop?: boolean): { renderNode: Options['renderNode'] } => ({
	renderNode: {
		[BLOCKS.EMBEDDED_ENTRY]: (node: Node): ReactNode => {
			if (node.data !== undefined && node.data.target.sys.contentType !== undefined) {
				const contentType = node.data.target.sys.contentType.sys.id;
				switch (contentType) {
					case 'blockBrandedSearchPageCollection':
						const RawBrandedSearchPageCollection = node.data.target as RawBrandedSearchPageCollection;
						const mappedCollection = mapBrandedSearchPageCollection(RawBrandedSearchPageCollection);
						return (
							<BrandedSearchRelatedCards
								cards={mappedCollection.brandedsearchpages}
								cardVariant={mappedCollection.cardVariant}
								pageType="content"
								title={mappedCollection.title}
								document={mappedCollection.description}
								backgroundColor={mappedCollection.backgroundColor}
							/>
						);
					case 'alertAtom':
						const alertAtomElement = node.data.target as Entry<RawAlertElement>;
						const fields = mapAlertElement(alertAtomElement);
						if (fields.description || fields.title) {
							return (
								<Alert
									title={fields.title}
									hyperlink={fields.hyperlink}
									variant={fields.variant}
									text={fields.description}
								/>
							);
						}
						break;
					case 'headerWithTick':
						return mapHeaderWithTickList(node.data.target).map((item, key) => (
							<HeadingWithTick key={key} title={item} />
						));
					case 'buttonRow':
						const buttonRowElement = node.data.target as Entry<RawButtonRow>;
						return (
							<RowWrapper key={buttonRowElement.sys.id}>
								{mapButtonRow(buttonRowElement)
									.buttons.filter((button) => button.link)
									.map((button, key) => (
										<LinkWrapper href={button.link} key={key}>
											<Button size={isTabletOrIsDesktop ? 'medium' : 'small'} asText>
												{button.title}
											</Button>
										</LinkWrapper>
									))}
							</RowWrapper>
						);
					/** Legacy */
					case 'imageGrid':
						const imageGridElement = node.data.target as Entry<RawImageGrid>;
						return (
							<ImageGrid key={imageGridElement.sys.id}>
								{mapImageGrid(imageGridElement).imageCards.map((image, key) => (
									<LinkCard
										key={key}
										title={image.buttonTitle}
										imgSrc={image.imageSrc}
										slug={image.link}
										target={image.target}
									/>
								))}
							</ImageGrid>
						);
					/** Legacy */
					case 'imageCard':
						const imageCardElement = node.data.target as Entry<RawImageCard>;
						const imageCard = mapImageCard(imageCardElement.fields);

						return (
							<LinkCard
								key={imageCardElement.sys.id}
								title={imageCard.buttonTitle}
								imgSrc={imageCard.imageSrc}
								slug={imageCard.link}
								variant={imageCard.variant}
								target={imageCard.target}
							/>
						);
					case 'button':
						const buttonElement = node.data.target as Entry<RawButtonElement>;
						const button = mapButtonElement(buttonElement.fields);
						return <ButtonWrapper {...button} />;
				}
			}
		},
		[BLOCKS.HEADING_1]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'heading2Xl' : 'headingXl'} as="h1">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_2]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'headingXl' : 'headingLg'} as="h2">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_3]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'headingLg' : 'headingMd'} as="h3">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_4]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'headingMd' : 'headingSm'} as="h4">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_5]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'headingSm' : 'headingXs'} as="h5">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.HEADING_6]: (_node: Node, children: ReactNode): ReactNode => (
			<TypographyWrapper variant={isTabletOrIsDesktop ? 'headingXs' : 'heading2Xs'} as="h6">
				{children}
			</TypographyWrapper>
		),
		[BLOCKS.PARAGRAPH]: (_node: Node, children: ReactNode): ReactNode => (
			<ParagraphWrapper variant={isTabletOrIsDesktop ? 'bodyMd' : 'bodySm'} as="p">
				{children}
			</ParagraphWrapper>
		),
		[BLOCKS.UL_LIST]: (node: Node): ReactNode => {
			return <UnorderedList items={mapUnorderedList(node as RawUnorderedList)} containsRichText />;
		},

		[BLOCKS.TABLE]: (_node: Node, children: ReactNode): ReactNode => {
			return <TableWrapper>{children}</TableWrapper>;
		},

		[BLOCKS.EMBEDDED_ASSET]: (node: Node): ReactNode => {
			const data = node.data?.fields || node.data?.target?.fields;
			/** REST embedded image */
			if (data?.file && 'image' in data.file.details) {
				const { file } = data;

				return <ImageWrapper fileData={file} />;
			}
			/** Gql embedded image */
			if (data?.contentType && data.contentType.includes('image')) {
				return <ImageWrapper fileData={data} />;
			}

			return <></>;
		},

		[INLINES.HYPERLINK]: (node: Node): ReactNode => {
			const document = node as Document;
			const hyperLink = document.content as Node[];
			const hyperlinkTextArray = hyperLink as Text[];

			const href = returnValidUrl(document.data.uri);

			return (
				<LinkWrapper href={href} target={getTarget(document.data.uri)}>
					{hyperlinkTextArray.map((hyperLinkText) => hyperLinkText.value)}
				</LinkWrapper>
			);
		},
		[INLINES.ENTRY_HYPERLINK]: (node: Node): ReactNode => {
			const document = node as Document;
			const hyperLink = document.content as Node[];
			const hyperlinkTextArray = hyperLink as Text[];
			const hyperLinkHref = document.data.target.fields.link
				? document.data.target.fields.link
				: document.data.target.fields.seo?.fields.slug;

			return (
				<LinkWrapper href={returnValidUrl(hyperLinkHref)} target={getTarget(hyperLinkHref)}>
					{hyperlinkTextArray.map((hyperLinkText) => hyperLinkText.value)}
				</LinkWrapper>
			);
		},
		[INLINES.ASSET_HYPERLINK]: (node: Node): ReactNode => {
			const data = node.data.target.fields;

			const { fileName, url } = data;
			return <DownloadImage nameIcon={fileName} pathIcon={url} />;
		},
		[INLINES.EMBEDDED_ENTRY]: (node: Node): ReactNode => {
			if (node.data !== undefined && node.data.target.sys.contentType !== undefined) {
				const contentType = node.data.target.sys.contentType.sys.id;
				switch (contentType) {
					case 'button':
						const buttonElement = node.data.target as Entry<RawButtonElement>;
						const button = mapButtonElement(buttonElement.fields);

						return (
							<LinkWrapper
								href={button.link}
								target={
									button.link.startsWith('http') ||
									button.link.startsWith('www') ||
									button.iconType === 'external'
										? '_blank'
										: undefined
								}
							>
								<Button
									variant={button.variant}
									size={isTabletOrIsDesktop ? 'medium' : 'small'}
									trailingIcon={button.iconType}
									asText
								>
									{button.title}
								</Button>
							</LinkWrapper>
						);
				}
			}
		},
	},
});

export const RichText: FC<RichTextProps> = ({ document }) => {
	const { isTabletOrIsDesktop } = useBreakpoints();

	if (hasNoContent(document)) return null;

	return <>{documentToReactComponents(document, options(isTabletOrIsDesktop))}</>;
};

// Assume empty when document only has nodes with no value in it
function hasNoContent(content: Document): boolean {
	if (!content?.content || !content.content.length) return false;

	return content.content.every((item) => {
		return (
			item.nodeType === 'paragraph' &&
			item.content.length === 1 &&
			item.content[0].nodeType === 'text' &&
			item.content[0].value.trim() === ''
		);
	});
}
