import { FC, PropsWithChildren, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Document } from '@contentful/rich-text-types';
import { CarouselControl } from '@vakantiesnl/components/src/atoms/CarouselControl/CarouselControl';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';

import useStyles from './ScrollableCardGroup.style';
import { RichText } from '../RichText';

export type ScrollableCardGroupProps = {
	containerClassName?: string;
	headingClassName?: string;
	listClassName?: string;
	heading?: string;
	document?: Document | null;
	listDataCy?: string;
	withScrollButtons?: boolean;
};

export const ScrollableCardGroup: FC<PropsWithChildren<ScrollableCardGroupProps>> = ({
	containerClassName,
	headingClassName,
	listClassName,
	heading,
	listDataCy,
	children,
	document,
	withScrollButtons = true,
}) => {
	const { classes, cx } = useStyles();
	const { isDesktop, isMobile } = useBreakpoints();

	const listRef = useRef<HTMLUListElement>(null);
	const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
	const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

	const setScrollableStates = useCallback(
		(element: Element) => {
			if (element) {
				const leftScroll = element.scrollLeft;
				const rightScroll = element.scrollWidth - element.clientWidth - leftScroll;

				setCanScrollLeft(leftScroll > 0);
				setCanScrollRight(rightScroll > 0);
			}
		},
		[setCanScrollLeft, setCanScrollRight],
	);

	const onScrollSpy = useCallback(
		(scrollEvt: SyntheticEvent) => {
			setScrollableStates(scrollEvt.currentTarget);
		},
		[setScrollableStates],
	);

	// set initial scroll button states
	useEffect(() => {
		if (listRef.current && !!withScrollButtons) {
			setScrollableStates(listRef.current);
		}
	}, [listRef, setScrollableStates, withScrollButtons]);

	const onNextCard = useCallback(() => {
		if (listRef?.current) {
			const scrollWidth = listRef.current.children[0]?.clientWidth || 0;
			listRef.current.scrollBy({ behavior: 'smooth', left: scrollWidth });
		}
	}, [listRef]);

	const onPrevCard = useCallback(() => {
		if (listRef?.current) {
			const scrollWidth = listRef.current.children[0]?.clientWidth || 0;
			listRef.current.scrollBy({ behavior: 'smooth', left: -scrollWidth });
		}
	}, [listRef]);

	return (
		<section className={cx(classes.container, containerClassName)}>
			<div className={classes.topWrapper}>
				{(heading || document) && (
					<div className={classes.headerContainer}>
						{heading && (
							<Typography
								variant={isDesktop ? 'headingXl' : 'headingLg'}
								as="h2"
								className={cx(classes.heading, headingClassName)}
							>
								{heading}
							</Typography>
						)}
						{!!document && <RichText document={document} />}
					</div>
				)}
				{!isMobile && withScrollButtons && (canScrollRight || canScrollLeft) && (
					<div className={classes.scrollButtonsContainer}>
						<CarouselControl icon="arrowLeft" disabled={!canScrollLeft} onClick={onPrevCard} />
						<CarouselControl icon="arrowRight" disabled={!canScrollRight} onClick={onNextCard} />
					</div>
				)}
			</div>
			<ul
				className={cx(classes.cardsList, listClassName)}
				data-cy={listDataCy}
				ref={listRef}
				onScroll={onScrollSpy}
			>
				{children}
			</ul>
		</section>
	);
};
