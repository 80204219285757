import { FC } from 'react';

import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { Price } from '@vakantiesnl/components/src/atoms/Price';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';

import { useStyles } from './PriceShape.styles';
import { shape8plus } from '../RatingBox/assets/svg';

type PriceShapeProps = {
	prefix: string;
	postfix: string;
	price: number;
};

export const PriceShape: FC<PriceShapeProps> = ({ prefix, postfix, price }) => {
	const { classes } = useStyles();

	return (
		<div className={classes.priceShapeContainer}>
			<Icon symbol={shape8plus} size="xLarge" className={classes.ratingBoxIcon} />
			<div className={classes.priceShapeContent}>
				<Typography as="span" variant="labelXs">
					{prefix}
				</Typography>
				<Price price={price} className={classes.price} primaryColor rounded />

				<Typography as="span" variant="labelXs">
					{postfix}
				</Typography>
			</div>
		</div>
	);
};
