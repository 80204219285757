import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	partnerItems: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: 0,
		gap: theme.spacing.small,
	},
}));
