import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	cardList: {
		display: 'flex',
		gap: theme.spacing.xsmall,
		overflowY: 'hidden',
		scrollbarWidth: 'none',
		msOverflowStyle: 'none',

		['&::-webkit-scrollbar']: {
			display: 'none',
		},

		[getFromBreakpoint('md')]: {
			gap: theme.spacing.xsmall,
		},
	},
	cardItem: {
		minWidth: '186px',
		maxWidth: '240px',
		flex: '1 1 0px',
	},
	verticalCard: {
		minWidth: '100%',
		width: '100%',
		height: 'auto',

		[getFromBreakpoint('md')]: {
			minWidth: '384px',
			height: '480px',
		},
	},
	verticalCardList: {
		flexWrap: 'wrap',
		gap: theme.spacing.small,

		[getFromBreakpoint('md')]: {
			gap: theme.spacing.medium,
			flexWrap: 'nowrap',
		},
	},
}));
