import { FC, useMemo } from 'react';

import { TrustPilot } from '@vakantiesnl/components/src/atoms/TrustPilot';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography/Typography';
import { Partners } from '@vakantiesnl/components/src/molecules/Partners/Partners';
import { useHeaderContext } from '@vakantiesnl/services/src/context/headerContext';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { HeaderNavBlock } from '@vakantiesnl/types';
import { useRouter } from 'next/router';

import { useStyles } from './PartnersBlock.style';

export const PartnersBlock: FC = () => {
	const router = useRouter();

	/** If leaving extra space is needed for sticky footer, so it's not blocking this component. */
	const withSpace = useMemo(
		() =>
			router?.pathname ? router.pathname.includes('accommodation') || router.pathname.includes('booking') : false,
		[router],
	);

	const { classes } = useStyles({ withSpace });
	const microCopy = useMicroCopyContext();
	const headerContext = useHeaderContext<HeaderNavBlock>();
	const partners = headerContext.partners;
	const paymentPartners = headerContext.paymentPartners;

	return (
		<div className={classes.partnersContainer}>
			<div className={classes.partners}>
				<Typography variant={'bodySm'} as={'span'} className={classes.typography}>
					{microCopy['guarantee.book']}
				</Typography>
				<Partners partners={partners} />
			</div>

			<div className={classes.partners}>
				<Typography variant={'bodySm'} as={'span'} className={classes.typography}>
					{microCopy['secure.payment']}
				</Typography>
				<Partners partners={paymentPartners} />
			</div>

			<div className={classes.partners}>
				<TrustPilot />
			</div>
		</div>
	);
};
