import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles<{ withSpace: boolean }>()((theme, { withSpace }) => ({
	partnersContainer: {
		height: 'auto',
		textAlign: 'center',
		gap: theme.spacing.small,
		padding: `${theme.spacing.xxsmall} 0`,
		marginBottom: withSpace ? theme.navbar.height : 0,
		display: 'grid',
		alignItems: 'center',

		[getFromBreakpoint('md')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: theme.spacing.jumbo,
			// Also flex height in case page has fullHeight setting
			flex: `0 0 ${theme.spacing.jumbo}`,
			gap: theme.spacing.medium,
			padding: 0,
		},
		[getFromBreakpoint('lg')]: {
			marginBottom: 0,
		},
	},
	partners: {
		display: 'grid',
		[getFromBreakpoint('md')]: {
			display: 'flex',
			gap: theme.spacing.xxsmall,
		},
	},
	typography: {
		paddingBottom: theme.spacing.xxsmall,
		[getFromBreakpoint('md')]: {
			paddingBottom: 0,
		},
	},
}));
