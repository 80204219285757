import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	container: {
		backgroundColor: theme.topbar.background.color,
	},
	emptyContainer: {
		backgroundColor: theme.topbar.background.color,
		height: theme.spacing.xxsmall,
	},
	content: {
		height: theme.topbar.height.mobile,
		display: 'flex',
		justifyContent: 'center',

		[getFromBreakpoint('md')]: {
			justifyContent: 'space-between',
			height: theme.topbar.height.desktop,
		},
	},
	uspTitle: {
		margin: 0,
		color: theme.topbar.foreground.color,
		textWrap: 'nowrap',
	},
	usp: {
		color: theme.topbar.foreground.color,
		display: 'flex',
		gap: theme.spacing.small,
		alignItems: 'center',
	},
	leftBlock: {
		display: 'flex',
		gap: theme.spacing.medium,
	},
	rightBlock: {
		display: 'none',

		[getFromBreakpoint('md')]: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing.medium,
		},
	},
}));
